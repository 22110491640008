import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import excel from "vue-excel-export";

Vue.config.productionTip = false;

// Global 3rd party plugins
import "bootstrap";
import "popper.js";
import "tooltip.js";
import "perfect-scrollbar";
import ToggleButton from "vue-js-toggle-button";

// Vue 3rd party plugins
import "./common/plugins/bootstrap-vue";
import "./common/plugins/perfect-scrollbar";
import "./common/plugins/highlight-js";
import "@babel/polyfill";
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import Swal from "sweetalert2";

/* Chart code */
// Themes begin
am4core.useTheme(am4themes_animated);
// Themes end

router.beforeEach((to, from, next) => {
  if (to.name === "forgot-pass" && !store.getters.isAuthenticated) {
    return next();
  }

  if (to.name != "login" && !store.getters.isAuthenticated) {
    next({ name: "login" });
  } else if (to.name === "login" && store.getters.isAuthenticated) {
    next({ name: "dashboard" });
  } else {
    let roleUser;
    try {
      roleUser = JSON.parse(localStorage.getItem("user"));
      if (to.meta.role && to.meta.role > roleUser.role) {
        Swal.fire({
          title: "Bạn không có quyền truy cập.",
          position: "center",
          icon: "error"
        });
      } else {
        next();
      }
    } catch (error) {
      localStorage.removeItem("user");
    }
  }
});

// if ('serviceWorker' in navigator) {
//     window.addEventListener('load', () => {
//         navigator.serviceWorker
//             .register('/service-worker.js')
//             .then((registration) => {})
//             .catch(() => {});
//     });
// }

Vue.use(excel);
Vue.use(ToggleButton);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
