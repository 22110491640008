import axios from "axios";
import { API_URL } from "./config";
import JwtService from "./jwt.service";
import NProgress from "nprogress";
import "nprogress/nprogress.css";

NProgress.configure({ showSpinner: false });

const service = axios.create({
  baseURL: API_URL,
  timeout: 30000
});

service.interceptors.request.use(
  config => {
    // NProgress.start()
    if (JwtService.getToken()) {
      config.headers["Authorization"] = JSON.parse(JwtService.getToken());
    }
    // NProgress.done()
    return config;
  },
  error => {
    // NProgress.done()
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  response => {
    // NProgress.done()
    return response.data;
  },
  error => {
    if (error.response && error.response.data) {
      // NProgress.done()
      if (error.response.data.status === 403) {
        JwtService.destroyToken()
        window.location.pathname = '/login'
      }

      return Promise.reject(error.response.data);
    }
    // NProgress.done()
    return Promise.reject(error);
  }
);
export default service;
