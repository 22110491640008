import JwtService from "@/common/jwt.service";
import { product, updateProduct, createProduct } from "./../api/product";

// action types
export const PRODUCT = "product";
export const UPDATE_PRODUCT = "updateProduct";
export const CREATE_PRODUCT = "createProduct";

// mutation types
export const SET_PRODUCT = "setProduct";
export const SET_ERROR = "setError";
export const SET_AUTH = "setUser";

const state = {
  errors: null,
  product: [],
  isAuthenticated: !!JwtService.getToken(),
  errorsUpdatePass: ""
};

const getters = {
  allProducts: state => state.product
};

const actions = {
  [PRODUCT]({ commit }, params) {
    return new Promise((resolve, reject) => {
      product(params)
        .then(data => {
          commit(SET_PRODUCT, data);
          resolve();
        })
        .catch(({ response }) => {
          commit(SET_ERROR, response.errors);
          reject();
        });
    });
  },
  [UPDATE_PRODUCT]({ commit }, data) {
    return new Promise((resolve, reject) => {
      updateProduct(data)
        .then(() => {
          commit(UPDATE_PRODUCT, data);
          resolve();
        })
        .catch(({ response }) => {
          commit(SET_ERROR, response);
          reject(response);
        });
    });
  },
  [CREATE_PRODUCT]({ commit }, data) {
    return new Promise((resolve, reject) => {
      createProduct(data)
        .then(() => {
          resolve();
        })
        .catch(errors => {
          commit(SET_ERROR, errors);
          reject(errors);
        });
    });
  },
  resetListProduct({ commit }) {
    commit("RESET_LIST_PRODUCT");
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  RESET_LIST_PRODUCT: state => {
    state.product = [];
  },
  [SET_PRODUCT](state, data) {
    state.product = data.data;
  },
  [UPDATE_PRODUCT](state, data) {
    const indexProduct = state.product.findIndex(({ _id }) => data.id === _id);
    if (indexProduct !== -1) {
      const product = state.product[indexProduct];
      product.name = data.name;
      product.price = data.price;
      product.position = data.position;
      product.status = data.status;
    }
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
