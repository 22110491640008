import JwtService from "@/common/jwt.service";
import { login, forgotPass, updatePass, getUsers } from "./../api/login";

// action types
export const SET_TITLE_BREADCRUMB = "setTitleBreadcrumb";
export const TITLE_BREADCRUMB = "titleBreadcrumb";

const state = {
  setTitleBreadcrumb: null
};

const getters = {
  titleBreadcrumb(state) {
    return state.setTitleBreadcrumb;
  },

  sidebar: state => state.app.sidebar
};

const actions = {
  [TITLE_BREADCRUMB](context, credentials) {
    context.commit(SET_TITLE_BREADCRUMB, credentials);
  }
};

const mutations = {
  [SET_TITLE_BREADCRUMB](state, title) {
    state.setTitleBreadcrumb = title;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
