import {
  createActivity,
  updateActivity,
  updateActivityProduct,
  cancelActivity,
  addToCart
} from "./../api/activity";
const state = {
  listActivities: []
};

const actions = {
  createActivity({ commit }, params) {
    return new Promise((resolve, reject) => {
      createActivity(params).then(data => {
        resolve(data);
      });
    });
  },
  updateActivity({ commit }, params) {
    return new Promise((resolve, reject) => {
      updateActivity(params).then(data => {
        resolve(data);
      });
    });
  },
  updateActivityProduct({ commit }, params) {
    return new Promise((resolve, reject) => {
      updateActivityProduct(params).then(data => {
        resolve(data);
      });
    });
  },
  cancelActivity({ commit }, params) {
    return new Promise((resolve, reject) => {
      cancelActivity(params).then(data => {
        resolve(data);
      });
    });
  },

  addToCart({ commit }, params) {
    return new Promise((resolve, reject) => {
      addToCart(params).then(data => {
        resolve(data);
      });
    });
  }
};

const mutations = {
  LIST_ACTIVITIES: (state, data) => {
    state.listActivities = data;
  }
};

export default {
  state,
  actions,
  mutations
};
