import request from "./../common/api";

export function getListOutlets(data) {
  return request({
    url: "secure/r1/outlet",
    method: "get",
    params: data
  });
}

export function createOutlet(data) {
  return request({
    url: "secure/r5/outlet",
    method: "post",
    data: data
  });
}

export function updateOutlet(data) {
  return request({
    url: `secure/r5/outlet/${data.id}`,
    method: "post",
    data: data.bodyParams
  });
}

export function getOutletReport(data) {
  return request({
    url: `secure/r4/report/outlet/${data.id}`,
    method: "get",
    params: data.params
  });
}

export function getDailyReportData(data) {
  return request({
    url: `secure/r1/outlet/daily-report?outletId=${data.outletId}&date=${data.date}`,
    method: "get"
  });
}

export function getDailyReportUser(data) {
  return request({
    url: `secure/r1/outlet/daily-report/user?outletId=${data.outletId}&date=${data.date}`,
    method: "get"
  });
}

export function updateDailyReportTable(data, outletId, date) {
  return request({
    url: `secure/r2/outlet/${outletId}/${date}`,
    method: "put",
    data
  });
}

export function getNotes(data) {
  let query = `?outletId=${data.outletId}`;

 
  if (data.limit) query += `&limit=${data.limit}`;
  if (data.currentPage) query += `&currentPage=${data.currentPage}`;

  return request({
    url: `secure/r1/outlet/note${query}`,
    method: "get"
  });
}

export function getOutletUsers(data) {
  return request({
    url: `secure/r1/outlet/user?outletId=${data.outletId}`,
    method: "get"
  });
}

export function getOutletPromos(data) {
  return request({
    url: `secure/r1/outlet/promo?outletId=${data.outletId}`,
    method: "get"
  });
}

export function getUsers(data) {
  return request({
    url: "secure/r1/user",
    method: "get",
    params: data
  });
}

export function getAllUsersManager(data) {
  return request({
    url: "secure/r3/user/manager",
    method: "get",
    params: data
  });
}

export function getUserSalary(data) {
  return request({
    url: `secure/r4/report/salary/${data.outletId}?month=${data.month}`,
    method: "get"
  });
}

export function getOutlets(data) {
  const query = `searchText=${data.text}&page=${data.page}`;

  return request({
    url: `secure/r1/outlet/all?${query}`,
    method: "get"
  });
}
