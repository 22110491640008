import request from "./../common/api";

export function getListSession(data) {
  return request({
    url: "secure/r1/session",
    method: "get",
    params: data
  });
}

export function getDetailSession(data) {
  return request({
    url: `secure/r1/session/${data}`,
    method: "get"
  });
}

export function createSession(data) {
  return request({
    url: "/secure/r1/session",
    method: "post",
    data: data
  });
}

export function updateSession(sessionId, data) {
  return request({
    url: `/secure/r1/session/${sessionId}`,
    method: "put",
    data: data
  });
}

export function cancelSession(sessionId) {
  return request({
    url: `/secure/r1/session/${sessionId}`,
    method: "delete"
  });
}
