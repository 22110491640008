import { actionPay } from "./../api/pay";
const state = {
  cartProducts: []
};

const getters = {
  getProductsInCart: () => {
    const cartProducts = localStorage.getItem("cartProducts");
    const parseCartProducts = JSON.parse(cartProducts);
    state.cartProducts = parseCartProducts?.cartProducts || [];
    const data = {
      isGiftCard: parseCartProducts ? parseCartProducts.isGiftCard : false,
      cartProducts: state.cartProducts,
      outletId: parseCartProducts ? parseCartProducts.outletId : ""
    };
    return data;
  }
};

const actions = {
  addProduct: (context, product) => {
    if (!product) return;
    context.commit("ADD_PRODUCT", product);
  },
  removeProduct: (context, index) => {
    context.commit("REMOVE_PRODUCT", index);
  },
  clearCart: context => {
    context.commit("CLEAR_CART", index);
  },
  actionPay: (context, params) => {
    return new Promise((resolve, reject) => {
      actionPay(params).then(() => {
        context.commit("ACTION_PAY");
        resolve();
      });
    });
  }
};

const mutations = {
  ADD_PRODUCT: (state, product) => {
    state.cartProducts.push(product.giftCard);
    const data = {
      isGiftCard: product.isGiftCard,
      cartProducts: state.cartProducts,
      outletId: product.outletId
    };
    localStorage.setItem("cartProducts", JSON.stringify(data));
  },
  REMOVE_PRODUCT: (state, index) => {
    const cartProducts = localStorage.getItem("cartProducts");
    const parseCartProducts = JSON.parse(cartProducts);
    state.cartProducts = parseCartProducts.cartProducts;
    state.cartProducts.splice(index, 1);

    const data = {
      isGiftCard: parseCartProducts.isGiftCard,
      cartProducts: state.cartProducts,
      outletId: parseCartProducts.outletId
    };
    localStorage.setItem("cartProducts", JSON.stringify(data));
  },
  CLEAR_CART: state => {
    state.cartProducts = [];
  },
  ACTION_PAY: state => {
    state.cartProducts = [];
    localStorage.removeItem("cartProducts");
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
