import request from "../common/api";

export function product(params) {
  return request({
    url: `secure/r1/product`,
    method: "get",
    params
  });
}

export function getProducts(data) {
  return request({
    url: `secure/r1/product/all?outletId=${data.outletId}&searchText=${data.text}&page=${data.page}`,
    method: "get"
  });
}

export function updateProduct(data) {
  return request({
    url: `secure/r3/product/update`,
    method: "post",
    data
  });
}

export function createProduct(data) {
  return request({
    url: `secure/r3/product/create`,
    method: "post",
    data
  });
}

export function getActivedProduct(outletId) {
  return request({
    url: `secure/r1/product/actived?outletId=${outletId}`,
    method: "get"
  });
}
