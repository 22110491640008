import request from "./../common/api";

export function createUser(data) {
  return request({
    url: "secure/r3/user",
    method: "post",
    data: data
  });
}

export function updateUser(data) {
  return request({
    url: `secure/r1/user/${data.id}`,
    method: "put",
    data: data.bodyParams
  });
}

export function getUserReport(data) {
  return request({
    url: `secure/r4/report/user/${data.id}`,
    method: "get",
    params: data.params
  });
}

export function getActivedOutlet() {
  return request({
    url: `secure/r1/user/outlets/actived`,
    method: "get"
  });
}

export function createNewNote(data) {
  return request({
    url: `secure/r1/user/note`,
    method: "post",
    data
  });
}

export function getUsers(data) {
  const query = `searchText=${data.text}&page=${data.page}&outletId=${data.outletId}`;

  return request({
    url: `secure/r1/user/all?${query}`,
    method: "get"
  });
}
