import {
  getListSession,
  createSession,
  getDetailSession
} from "./../api/session";
const state = {
  listSession: [],
  detailSession: null,
  listClient: []
};

const getters = {
  allSessions: state => state.listSession,
  detailSession: state => state.detailSession
};

const actions = {
  getListSession({ commit }, params) {
    return new Promise((resolve, reject) => {
      getListSession(params).then(data => {
        commit("LIST_SESSIONS", data.data);
        resolve();
      });
    });
  },
  getDetailSession({ commit }, params) {
    return new Promise((resolve, reject) => {
      getDetailSession(params).then(data => {
        commit("DETAIL_SESSION", data.data);
        resolve();
      });
    });
  },
  createSession({ commit }, params) {
    return new Promise((resolve, reject) => {
      createSession(params).then(data => {
        resolve(data);
      });
    });
  }
};

const mutations = {
  LIST_CLIENTS: (state, data) => {
    state.listClient = data;
  },
  LIST_SESSIONS: (state, data) => {
    state.listSession = [];
    state.listSession = [...state.listSession, ...data];
  },
  DETAIL_SESSION: (state, data) => {
    state.detailSession = data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
