import { createUser, updateUser } from "./../api/user";
const state = {
  user: []
};

const actions = {
  createUser({ commit }, params) {
    return new Promise((resolve, reject) => {
      createUser(params)
        .then(res => {
          resolve(res);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  updateUser({ commit }, params) {
    return new Promise((resolve, reject) => {
      updateUser(params).then(() => {
        resolve();
      });
    });
  }
};

const mutations = {};

export default {
  state,
  actions,
  mutations
};
