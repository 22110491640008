import request from "./../common/api";

export function updateActivity(data) {
  return request({
    url: `/secure/r1/activity/${data.id}`,
    method: "put",
    data: data.params
  });
}

export function createActivity(data) {
  return request({
    url: "/secure/r1/activity",
    method: "post",
    data: data
  });
}

export function updateActivityProduct(data) {
  return request({
    url: `/secure/r2/session/custom-activity/${data.id}`,
    method: "put",
    data: data.params
  });
}

export function cancelActivity(data) {
  return request({
    url: `/secure/r2/session/${data.id}`,
    method: "delete"
  });
}

export function addToCard(data) {
  return request({
    url: `/secure/r2/session/addToCard/${data.id}`,
    method: "put"
  });
}
