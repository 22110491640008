import Vue from "vue";
import Router from "vue-router";
import { UserRole } from "./constans";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      name: "login",
      path: "/login",
      component: () => import("@/views/auth/Login")
    },
    {
      name: "forgot-pass",
      path: "/forgot-pass",
      component: () => import("@/views/auth/ForgotPass")
    },
    {
      path: "/",
      redirect: "/dashboard"
    },
    {
      name: "dashboard",
      path: "/dashboard",
      component: () => import("@/views/dashboard/DashBoard")
    },
    {
      name: "",
      path: "/outlet",
      component: () => import("@/views/outlet/Outlet"),
      children: [
        {
          path: "/",
          name: "outlet-list",
          component: () => import("@/views/outlet/List"),
          meta: { role: UserRole.OutletManager }
        },
        {
          name: "outlet-create",
          path: "create",
          component: () => import("@/views/outlet/Create"),
          meta: { role: UserRole.OutletManager }
        },
        {
          name: "outlet-edit",
          path: "edit/:item",
          component: () => import("@/views/outlet/Edit"),
          meta: { role: UserRole.OutletManager }
        },
        {
          name: "outlet-report",
          path: "report",
          component: () => import("@/views/outlet/Report"),
          meta: { role: UserRole.OutletBoss }
        }
      ]
    },
    {
      name: "",
      path: "/user",
      component: () => import("@/views/user/User"),
      children: [
        {
          path: "/",
          name: "user-list",
          component: () => import("@/views/user/List"),
          meta: { role: UserRole.OutletManager }
        },
        {
          name: "user-create",
          path: "create",
          component: () => import("@/views/user/Create"),
          meta: { role: UserRole.OutletManager }
        },
        {
          name: "user-edit",
          path: "edit/:item",
          component: () => import("@/views/user/Edit"),
          meta: { role: UserRole.OutletManager }
        },
        {
          name: "user-report",
          path: "report/:id",
          component: () => import("@/views/user/Report"),
          meta: { role: UserRole.OutletManager }
        },
        {
          path: "report-list",
          name: "user-report-list",
          component: () => import("@/views/user/ReportList"),
          meta: { role: UserRole.OutletBoss }
        }
      ]
    },
    {
      name: "",
      path: "/cart",
      component: () => import("@/views/cart/Layout"),
      children: [
        {
          path: "/",
          name: "cart",
          meta: { role: UserRole.Cashier },
          component: () => import("@/views/cart/Cart")
        }
      ]
    },
    {
      name: "",
      path: "/invoice",
      component: () => import("@/views/invoice/Invoice"),
      children: [
        {
          path: "/",
          name: "invoice-list",
          meta: { role: UserRole.Cashier },
          component: () => import("@/views/invoice/List")
        },
        {
          name: "invoice-detail",
          path: "detail/:id",
          meta: { role: UserRole.Cashier },
          component: () => import("@/views/invoice/Detail")
        }
      ]
    },
    {
      name: "",
      path: "/product",
      component: () => import("@/views/product/Product"),
      children: [
        {
          path: "/",
          name: "product-list",
          meta: { role: UserRole.OutletManager },
          component: () => import("@/views/product/List")
        },
        {
          name: "product-edit",
          path: "edit/:item",
          meta: { role: UserRole.OutletManager },
          component: () => import("@/views/product/Edit")
        },
        {
          name: "product-create",
          path: "create",
          meta: { role: UserRole.OutletManager },
          component: () => import("@/views/product/Create")
        }
      ]
    },
    {
      name: "",
      path: "/forward-client",
      component: () => import("@/views/forward-client/ForwardClient"),
      children: [
        {
          path: "/",
          name: "forward-client-list",
          component: () => import("@/views/forward-client/List")
        },
        {
          name: "forward-client-service",
          path: "/forward-client-service",
          component: () => import("@/views/forward-client/Service")
        }
      ]
    },
    {
      name: "",
      path: "/gift-card",
      component: () => import("@/views/gift-card/GiftCard"),
      children: [
        {
          path: "/",
          name: "gift-card-list",
          meta: { role: UserRole.Cashier },
          component: () => import("@/views/gift-card/List")
        },
        {
          path: "/create",
          name: "gift-card-create",
          meta: { role: UserRole.Cashier },
          component: () => import("@/views/gift-card/Create")
        },
        {
          path: "/gift-card/edit",
          name: "gift-card-edit",
          meta: { role: UserRole.Cashier },
          component: () => import("@/views/gift-card/Edit")
        }
      ]
    },
    {
      name: "",
      path: "/promo",
      component: () => import("@/views/promo/Promo"),
      children: [
        {
          path: "/",
          name: "promo-list",
          meta: { role: UserRole.OutletManager },
          component: () => import("@/views/promo/List")
        },
        {
          path: "/create",
          name: "promo-create",
          meta: { role: UserRole.OutletManager },
          component: () => import("@/views/promo/Create")
        },
        {
          path: "/edit",
          name: "promo-edit",
          meta: { role: UserRole.OutletManager },
          component: () => import("@/views/promo/Edit")
        }
      ]
    },
    {
      name: "",
      path: "/salary",
      component: () => import("@/views/salary/Salary"),
      children: [
        {
          path: "/report-salary",
          name: "report-salary",
          meta: { role: UserRole.OutletBoss },
          component: () => import("@/views/salary/ReportSalary")
        }
      ]
    },
    {
      path: "*",
      redirect: "/404"
    },
    {
      path: "/404",
      name: "not-found",
      component: () => import("@/views/error/NotFound.vue")
    }
  ]
});
