import request from "./../common/api";

export function login(data) {
  return request({
    url: "public/login",
    method: "post",
    data: data.credentials
  });
}

export function forgotPass(data) {
  return request({
    url: "public/send-pin",
    method: "post",
    data: data
  });
}

export function updatePass(data) {
  return request({
    url: "public/receive-pin",
    method: "post",
    data: data
  });
}

export function getUsers(data) {
  return request({
    url: "secure/r1/user",
    method: "get",
    params: data
  });
}
