import { getListPromo, createPromo, updatePromo } from "./../api/promo";

const state = {
  promo: []
};

const actions = {
  getListPromo({ commit }, params) {
    return new Promise((resolve, reject) => {
      getListPromo(params).then(data => {
        commit("LIST_PROMOS", data.data);
        resolve();
      });
    });
  },

  createPromo({ commit }, params) {
    return new Promise((resolve, reject) => {
      createPromo(params).then(data => {
        resolve();
      });
    });
  },

  updatePromo({ commit }, params) {
    return new Promise((resolve, reject) => {
      updatePromo(params).then(data => {
        commit("UPDATE_PROMO", params);
        resolve();
      });
    });
  }
};

const mutations = {
  LIST_PROMOS: (state, data) => {
    state.promo = data;
  },
  UPDATE_PROMO: (state, data) => {
    console.log(state.promo);
    const indexOutlet = state.promo.data.findIndex(
      item => item._id === data.id
    );
    if (indexOutlet !== -1) {
      state.promo.data[indexOutlet].status = data.params.status;
    }
  }
};

export default {
  state,
  actions,
  mutations
};
