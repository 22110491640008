import request from "./../common/api";

export function getListPromo(data) {
  return request({
    url: "secure/r1/promo",
    method: "get",
    params: data
  });
}

export function createPromo(data) {
  return request({
    url: "secure/r3/promo",
    method: "post",
    data: data
  });
}

export function updatePromo(data) {
  return request({
    url: `secure/r3/promo/${data.id}`,
    method: "put",
    data: data.params
  });
}
