import { getListOutlets, createOutlet, updateOutlet } from "./../api/outlet";
const state = {
  outlets: []
};

const getters = {
  allOutlets: state => state.outlets
};

const actions = {
  setOutlets({ commit }, params) {
    return new Promise((resolve, reject) => {
      getListOutlets(params).then(data => {
        commit("LIST_OUTLETS", data.data);
        resolve();
      });
    });
  },

  resetListOutlets({ commit }) {
    commit("RESET_LIST_OUTLETS");
  },

  createOutlet({ commit }, params) {
    return new Promise((resolve, reject) => {
      createOutlet(params).then(() => {
        resolve();
      });
    });
  },
  updateOutlet({ commit }, params) {
    return new Promise((resolve, reject) => {
      updateOutlet(params).then(() => {
        commit("UPDATE_OUTLETS", params);
        resolve();
      });
    });
  }
};

const mutations = {
  LIST_OUTLETS: (state, data) => {
    state.outlets = data;
  },

  RESET_LIST_OUTLETS: (state, data) => {
    state.outlets = [];
  },

  UPDATE_OUTLETS: (state, data) => {
    const indexOutlet = state.outlets.findIndex(item => item._id === data.id);
    if (indexOutlet !== -1) {
      state.outlets[indexOutlet].status = data.bodyParams.status;
    }
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
