import request from "./../common/api";

export function getAllInvoiceOfOutlet(data) {
  return request({
    url: `secure/r2/invoice/${data.params.outletId}`,
    method: "get",
    params: data.query
  });
}

export function getAllInvoice(data) {
  return request({
    url: `secure/r2/invoice/outlet`,
    method: "get",
    params: data
  });
}

export function createInvoice(data) {
  return request({
    url: "secure/r2/invoice",
    method: "post",
    data
  });
}

export function cancelOrder(id) {
  return request({
    url: `secure/r2/invoice/${id}`,
    method: "delete"
  });
}

export function getInvoice(id) {
  return request({
    url: `secure/r2/invoice?id=${id}`,
    method: "get"
  });
}

export function payInvoice(id, data) {
  return request({
    url: `secure/r2/invoice/${id}`,
    method: "put",
    data
  });
}

export function getPaymentDetail(id) {
  return request({
    url: `secure/r2/invoice/payment-detail/${id}`,
    method: "get"
  });
}

export function getAllDrafInvoiceOfOutlet(outletId) {
  return request({
    url: `secure/r2/invoice/cart?id=${outletId}`,
    method: "get"
  });
}

export function removeGiftcardInvoice(giftCardId) {
  return request({
    url: `secure/r2/gift-card/cart/${giftCardId}`,
    method: "delete"
  });
}
