import JwtService from "@/common/jwt.service";
import { login, forgotPass, updatePass, getUsers } from "./../api/login";

// action types
export const LOGIN = "login";
export const LOGOUT = "logout";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_ERROR = "setError";
export const FORGOT_PASS = "forgotPass";
export const UPDATE_PASS = "updatePass";
export const SET_ERROR_UPDATE_PASS = "errorUpdatePass";
export const GET_USERS = "getUsers";
export const SET_LIST_USERS = "setListUsers";
export const RESET_LIST_USERS = "resetListUsers";
export const UPDATE_LIST_USERS = "updateListUsers";
export const REMOVE_ERROR_LOGIN = "removeErrorLogin";
export const SET_ERROR_LOGIN = "setErrorLogin";

const state = {
  errors: null,
  user: {},
  isAuthenticated: !!JwtService.getToken(),
  errorsUpdatePass: "",
  listUser: [],
  token: JwtService.getToken()
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  }
};

const actions = {
  [LOGIN](context, credentials) {
    return new Promise((resolve, reject) => {
      login({ credentials })
        .then(data => {
          context.commit(SET_AUTH, data);
          resolve();
        })
        .catch(error => {
          context.commit(SET_ERROR, error.errors);
          reject(error);
        });
    });
  },
  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
  },

  [FORGOT_PASS](context, credentials) {
    return new Promise((resolve, reject) => {
      forgotPass(credentials)
        .then(() => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [UPDATE_PASS](context, credentials) {
    return new Promise((resolve, reject) => {
      updatePass(credentials)
        .then(() => {
          resolve();
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR_UPDATE_PASS, response.errors);
          reject();
        });
    });
  },

  [GET_USERS](context, credentials) {
    return new Promise((resolve, reject) => {
      getUsers(credentials).then(response => {
        context.commit(SET_LIST_USERS, response.data.usersList);
        resolve(response.data);
      });
    });
  },

  [UPDATE_LIST_USERS](context, credentials) {
    context.commit(RESET_LIST_USERS);
  },

  [REMOVE_ERROR_LOGIN](context, credentials) {
    context.commit(SET_ERROR_LOGIN);
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AUTH](state, user) {
    state.isAuthenticated = true;
    state.user = user;
    state.errors = {};
    localStorage.setItem("user", handleJSON(state.user.data));
    JwtService.saveToken(JSON.stringify(user.data.token));
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    JwtService.destroyToken();
  },
  [SET_ERROR_UPDATE_PASS](state, error) {
    state.errorsUpdatePass = error;
  },
  [SET_LIST_USERS](state, data) {
    state.listUser = data;
  },
  [RESET_LIST_USERS]: (state, data) => {
    state.listUser = [];
  },
  [SET_ERROR_LOGIN]: state => {
    state.errors = "";
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};

function handleJSON(data) {
  try {
    return JSON.stringify(data);
  } catch (error) {
    return "";
  }
}
