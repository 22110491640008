import { getListInvoices, createInvoice } from "./../api/invoice";

const state = {
  invoices: []
};

const getters = {
  allInvoices: state => state.invoices
};

const actions = {
  getInvoice({ commit }, params) {
    return new Promise((resolve, reject) => {
      getListInvoices(params).then(data => {
        commit("LIST_INVOICES", data.data);
        resolve();
      });
    });
  },
  createInvoice({ commit }, params) {
    return new Promise((resolve, reject) => {
      createInvoice(params).then(data => {
        resolve(data);
      });
    });
  }
};

const mutations = {
  LIST_INVOICES: (state, data) => {
    state.invoices = [];
    state.invoices = [...state.invoices, ...data];
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
