export const UserRole = {
  Staff: 1,
  Cashier: 2,
  OutletManager: 3,
  OutletBoss: 4,
  CEO: 5
};

export const Status = {
  Removed: 0,
  Actived: 1,
  NotActived: 2
};

export const SessionStatus = {
  InProcess: 0,
  ReadyToPay: 1,
  Paid: 2,
  Locked: 3,
  Cancel: 4
};

export const InvoiceStatus = {
  Draft: 0,
  Canceled: 1,
  Pending: 2,
  Paid: 3,
  Delivered: 4,
};

export const LABEL_VN = {
  labelPrevYear: "Năm trước",
  labelPrevMonth: "Tháng trước",
  labelCurrentMonth: "Tháng hiện tại",
  labelNextMonth: "Tháng tiếp theo",
  labelNextYear: "Năm tiếp theo",
  labelToday: "Hôm nay",
  labelNoDateSelected: "Chọn ngày",
  labelHelp: "Sử dụng các phím mũi tên để điều hướng qua lịch."
};

export const VARIANTS = ["primary", "warning", "success", "dark"];
