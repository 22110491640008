import Vue from "vue";
import Vuex from "vuex";

import htmlClass from "./htmlclass.module";
import config from "./config.module";
import auth from "./auth.module";
import outlet from "./outlet.module";
import user from "./user.module";
import product from "./product.module";
import breadcrumbs from "./breadcrumbs.module";
import invoice from "./invoice.module";
import sessions from "./session.module";
import promo from "./promo.module";
import activity from "./activity.module";
import cart from "./cart.module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    htmlClass,
    config,
    auth,
    outlet,
    invoice,
    product,
    user,
    breadcrumbs,
    sessions,
    promo,
    activity,
    cart
  }
});
